import { useApi } from '~/composables/useApi';

export const useCartInfo = () => {
  const { query } = useApi();

  const getCartSelectedPaymentMethod:any = async (cart_id) =>{
    const myGraphqlQuery = `
      query {
        cart(cart_id: "${cart_id}") {
          selected_payment_method{
            code
          }
        }
      }
    `;
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getCartSelectedPaymentMethod,
  }
}


export default useCartInfo;