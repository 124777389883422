import {
  useContext,
  useRouter,
  ref,
} from "@nuxtjs/composition-api";

export const useFtPaymentRedirect = () => {
  const { app,$config } = useContext();
  const magentoCheckoutUrl = $config.magentoCheckoutUrl;
  const router = useRouter();
  const paymentMethod = ref(["grabpay", "paypal_express", "p2c2p", "citibank", "dbsipp", "chcybersource"]);
  const paymentRedirect= async  (code,order) =>{
    
    if(order){
      if(paymentMethod.value.find(pym => pym == code)){

        if(code == "paypal_express"){
          const paymentRoute = app.localeRoute({
            name: `${code}-redirect`,
            query: {
              order: order,
            },
          });
          await router.push(paymentRoute);
        }else{
          const paymentRoute = app.localeRoute({
            name: "redirect-to-magento",
            query: {
              order: order,
              code: code
            },
          });
          await router.push(paymentRoute);
        }
      }else{
        const paymentRoute = app.localeRoute({
          name: "thank-you",
          query: {
            order: order,
          },
        });
         router.push(paymentRoute);
      }
    }
  }
  
  return {
    paymentRedirect,
  }
}


export default useFtPaymentRedirect;