import { useApi } from '~/composables/useApi';
import { useCart } from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { computed, onMounted, ComputedRef, ref, readonly, DeepReadonly } from "@nuxtjs/composition-api";
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import moment from 'moment';
import { merge } from 'lodash-es';

export interface ProductDeliveryInfo {
  standard: StandardDelivery,
  express: ExpressDelivery, 
  postal: PostalDelivery
}

export interface StandardDelivery {
  delivery_group_name: String,
  earliest_delivery_date: number,
  fulfillment_agency_name: String,
  earliest_delivery_date_display: String,
  free_delivery_min_subtotal: number
  delivery_fee_type: String
}
export interface ExpressDelivery {
  can_express: Boolean,
  delivery_group_name: String,
  earliest_delivery_date: number,
  earliest_timeslot: String
  fulfillment_agency_name: String,
  earliest_delivery_date_display: String,
  earliest_timeslot_display: String
}

export interface PostalDelivery {
  delivery_group_name: String
  earliest_delivery_date: number
  fulfillment_agency_name: String
  postal_estimated_shipping_day: String
}

export interface CartDeliveryGroup {
  delivery_groups: [DeliveryGroup]
}

export interface CheckoutDeliveryGroups {
  delivery_groups: [CheckoutDeliveryGroup]
}

export interface CheckoutDeliveryGroup {
  standard: StandardCheckoutDeliveryGroup
  express: ExpressCheckoutDeliveryGroup
  items: [DeliveryGroupItem]
  nogo: Boolean
}

export interface StandardCheckoutDeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  free_delivery_min_subtotal: number
  delivery_charge: String
  available_dates: [String]
  mattress_disposal: Boolean
  timeslots: [Timeslot]
}

export interface ExpressCheckoutDeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  earliest_delivery_date: String
  delivery_charge: String
  mattress_disposal: Boolean
  timeslots: [Timeslot]
}

export interface Timeslot {
  timeslot_start: String
  timeslot_end: String
  is_closed: Boolean
}

export interface DeliveryGroup {
  delivery_group_id: number
  delivery_group_name: String
  fulfillment_agency_name: String
  plan_type: String
  free_delivery_min_subtotal: number
  first_item_charge: number
  available_dates: [String]
  items: [DeliveryGroupItem]
}

export interface DeliveryGroupItem {
  item_id: String
  product_id: String
  product_name: String
  earliest_available_date: EarliestAvailableDate
}

export interface EarliestAvailableDate {
  standard: String
  express: String
}

export interface SaveQuoteDeliveryInfo {
  status: Boolean
}

export interface SaveQuoteRemarksInfo {
  error: Boolean
}

export interface DeliveryGroupTimeslots {
  timeslots: [Timeslot]
}

export interface DeliveryQuote {
  delivery_date: String
  delivery_time_start: String
  delivery_time_end: String
  remarks: String
}

export interface DeliveryQuoteValidation {
  status: number
}

export const useFtDelivery = () => {
  const { query } = useApi();
  const { cart, load: loadCart, loading: loadingCart } = useCart();

  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const { load: loadShippingAddress, save: saveShippingAddress } = useShippingProvider();

  onMounted(() => {
    if (totalItems.value && !cart.value.id) {
      loadCart();
    }
  });
  
  const cart_id = computed(() => cart?.value?.id); 
  
  const loading = ref(false);

  const getProductDelivery = async (product) =>{
    loading.value = true;
    const selectedOptions = computed(()=>{
      if (product.type=="BundleProduct"){
        let option_string: String = Buffer.from(JSON.stringify(product.selected_options)).toString('base64');
        // option_string = option_string.split("\"").join("")
        return ", selected_options: \"" + option_string + "\"";
      }
      else{
        return ""
      }
    });
    const cartIdFilter = computed(()=>{
      if (totalItems.value){
        return ", cart_id: \"" + cart.value.id + "\"";
      }
      else{
        return ""
      }
    });
    const configurableChildProductId = computed(()=>{
      if (product.type=="ConfigurableProduct"){
        return ", configurable_child_product_id: \"" + product.configurable_child_product_id + "\"";
      }
      else{
        return ""
      }
    });

    const qty = computed(() => product.qty ? product.qty : 1);
        
    // , cart_id: "${cart_id.value}"
    const myGraphqlQuery = `
    query {
      getProductDeliveryInfo(product_id: "${product.uid}", qty: ${qty.value} ${configurableChildProductId.value} ${cartIdFilter.value} ${selectedOptions.value}){
        standard{
          delivery_group_name
          fulfillment_agency_name
          earliest_delivery_date
          free_delivery_min_subtotal
          delivery_fee_type
        }
        express{
          delivery_group_name
          fulfillment_agency_name
          earliest_delivery_date
          earliest_timeslot
        }
        postal{
          delivery_group_name
          fulfillment_agency_name
          postal_estimated_shipping_day
        }
      }
    }
    `;
    const { data } = await query<{ getProductDeliveryInfo: ProductDeliveryInfo }>(myGraphqlQuery);
    const {getProductDeliveryInfo} = data;
    loading.value = false;
    // getProductDeliveryInfo.standard.earliest_delivery_date_display = getFormattedDate(getProductDeliveryInfo?.standard?.earliest_delivery_date ?? 0);
    
    const getStandardDeliveryGroupName = () => getProductDeliveryInfo?.standard?.delivery_group_name ?? '';
    const getStandardDeliveryDateDisplay = () => getFormattedDate(data.getProductDeliveryInfo?.standard?.earliest_delivery_date ?? 0);
    
    const getExpressDeliveryGroupName = () => getProductDeliveryInfo?.express?.delivery_group_name ?? '';
    // const getCanExpress = () => (getProductDeliveryInfo?.express?.delivery_group_name != null && getProductDeliveryInfo.express.delivery_group_name != '') ? true : false;
    const getCanExpress = () => getProductDeliveryInfo?.express?.delivery_group_name ? true : false;
    const getExpressDeliveryEaliestTimeslot = () => getProductDeliveryInfo?.express?.earliest_timeslot ?? '';
    const getExpressDeliveryDate =  getProductDeliveryInfo?.express?.earliest_delivery_date ?? 0;
    const expressDifferenceInDay = () => {
      const now = moment().format("YYYY-MM-DD");
      if (getCanExpress()){
        const expressEarliestDate = moment(getExpressDeliveryDate*1000, 'x').format("YYYY-MM-DD");
        return moment(expressEarliestDate).diff(now, 'days'); //calcualte different between now and express earliest date
      }
    };
    

    const getExpressDeliveryDayDisplay = ()=>{
      if (getCanExpress){
        if (expressDifferenceInDay() === 0){
          return "Today";
        }
        if (expressDifferenceInDay() === 1){
          return "Tomorrow";
        }
        else{
          return moment(getExpressDeliveryDate*1000, 'x').format("dddd, D MMMM YYYY");
        }
      }
    };

    
    // console.log(standard_delivery_date);
    
    // const isExpress = computed(()=>(data.value.getProductDeliveryInfo.express.delivery_group_name != null && data.value.getProductDeliveryInfo.express.delivery_group_name != '') ? true : false);

    return {
      data,
      loading,
      getStandardDeliveryGroupName,
      getStandardDeliveryDateDisplay,
      getExpressDeliveryGroupName,
      getCanExpress,
      getExpressDeliveryEaliestTimeslot,
      getExpressDeliveryDayDisplay
    };
  }

  const cartDeliveryGroup = ref<CartDeliveryGroup | null>();

  const loadingCartDeliveryGroup = ref(true);

  const getCartDeliveryGroup = async () => {

    if (!totalItems.value){
      return;
    }
    const graphqlQuery = `
      query {
          getCartDeliveryGroup(cart_id: "${cart.value.id}") {
            delivery_groups {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              free_delivery_min_subtotal
              first_item_charge
              items {
                item_id
                product_id
                product_name
                earliest_available_date {
                  standard
                  express
                }
              }
            }
          }
        }
    `;

    const { data, errors } = await query<{ getCartDeliveryGroup: CartDeliveryGroup }>(graphqlQuery);
    loadingCartDeliveryGroup.value = false;
    // const { getCartDeliveryGroup } = data;
    if (!errors){
      cartDeliveryGroup.value = data.getCartDeliveryGroup ?? null;
    }
  }

  const checkoutDeliveryGroup = ref({});

  const loadingCheckoutDeliveryGroup = ref(true);

  const getCheckoutDeliveryGroup = async () => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query {
      getCheckoutDeliveryGroup(cart_id: "${cart.value.id}") {
          delivery_groups {
            nogo
            standard {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              free_delivery_min_subtotal
              after_hours_time_start
              after_hours_delivery_charge
              delivery_charge_amount {
                charge
                location_surcharge
                after_hour_surcharge
              }
              available_dates
              mattress_disposal
              timeslots {
                id
                timeslot_start
                timeslot_end
                is_closed
              }
            }
            express {
              delivery_group_id
              delivery_group_name
              fulfillment_agency_name
              plan_type
              delivery_date
              after_hours_time_start
              after_hours_delivery_charge
              delivery_charge_amount {
                charge
                location_surcharge
                after_hour_surcharge
              }
              mattress_disposal
              timeslots {
                id
                timeslot_start
                timeslot_end
                is_closed
              }
            }
            items {
              item_id
              product_id
              product_name
              earliest_available_date {
                standard
                express
              }
            }
          }
        }
      }
  `;
    const { data: { getCheckoutDeliveryGroup } } = await query<{ getCheckoutDeliveryGroup: CheckoutDeliveryGroups }>(graphqlQuery);
    // const { getCheckoutDeliveryGroup } = data;
    checkoutDeliveryGroup.value = merge({}, checkoutDeliveryGroup.value, getCheckoutDeliveryGroup);
    loadingCheckoutDeliveryGroup.value = false;
  }

  const timeslots = ref();

  const getDeliveryGroupTimeslotsForDate = async (delivery_group_id, delivery_date) => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query {
      getDeliveryGroupTimeslots(delivery_group_id: "${delivery_group_id}", delivery_date: "${delivery_date}", cart_id: "${cart.value.id}") 
        {
          timeslots{
            id
            timeslot_start
            timeslot_end
            is_closed
          }
        }
      }
  `;
    const { data: { getDeliveryGroupTimeslots } } = await query<{ getDeliveryGroupTimeslots: DeliveryGroupTimeslots }>(graphqlQuery);
    // const { getCheckoutDeliveryGroup } = data;
    timeslots.value = merge({}, getDeliveryGroupTimeslots);
  }

  const delivery_quote = ref();

  const getDeliveryQuote = async (delivery_group_id) => {

    if (!totalItems.value){
      return;
    }

    const graphqlQuery = `
      query {
        getDeliveryQuote(delivery_group_id: "${delivery_group_id}", cart_id:"${cart_id.value}")
        {
          delivery_date
          delivery_time_start
          delivery_time_end
          remarks
          require_mattress_disposal
        }
      }
    `;
    const { data: { getDeliveryQuote } } = await query<{ getDeliveryQuote: DeliveryQuote }>(graphqlQuery);
    delivery_quote.value = merge({}, delivery_quote.value, getDeliveryQuote);
  }

  const getFormattedDate = ( timestamp ) => {
    return moment(timestamp*1000, 'x').format("dddd, D MMMM YYYY");
  }

  const getDisplayDate = (timestamp) =>{
    const now = moment().format("YYYY-MM-DD");
    const formatTimestamp = moment(timestamp*1000, 'x').format("YYYY-MM-DD");
    const differentInDay = moment(formatTimestamp).diff(now, 'days'); //calcualte different between now and express earliest date
    if (differentInDay === 0){
      return "Today";
    }
    if (differentInDay === 1){
      return "Tomorrow";
    }
    else{
      return moment(timestamp*1000, 'x').format("dddd, D MMMM YYYY");
    }
     
  }

  const saveQuoteDeliveryInfoResponse = ref();

  const saveQuoteDeliveryInfo = async (quote_delivery_info) => {
    try {
      loading.value = true;
      var stringify_quote_delivery_info = JSON.stringify(quote_delivery_info);
      var stringify_quote_delivery_info = stringify_quote_delivery_info.replace(/"([^"]+)":/g, '$1:');
      if (!totalItems.value){
        return;
      }
  
    const graphqlQuery = `
      mutation {
        saveQuoteDeliveryInfo(cart_id: "${cart.value.id}", delivery_group_info: ${stringify_quote_delivery_info}) {
          error
        }
      }
      `;
      const { data } = await query<{ saveQuoteDeliveryInfo: SaveQuoteDeliveryInfo }>(graphqlQuery);
      const { saveQuoteDeliveryInfo } = data;
  
      saveQuoteDeliveryInfoResponse.value = merge({}, saveQuoteDeliveryInfoResponse.value, saveQuoteDeliveryInfo);
  
      const shippingMethod = await loadShippingAddress();
      // Logger.debug('[Magento]: Defining the shipping method as:', JSON.stringify(shippingMethod, null, 2));
      await saveShippingAddress({
        shippingMethod: {
          carrier_code: shippingMethod.carrier_code,
          method_code: shippingMethod.method_code,
        },
      });
      
    }
    catch (err) {
      
    }
    finally {
      loading.value = false;
    }

  }

  const saveQuoteRemarks = async (remarks) => {
    if (!remarks){
      return; // return when no remarks
    }

    try {
      loading.value = true;
      if (!totalItems.value){
        return;
      }
  
    const graphqlQuery = `
      mutation {
        saveQuoteRemarks(cart_id: "${cart.value.id}", remarks: "${remarks}") {
          error
        }
      }
      `;
      const { data } = await query<{ saveQuoteRemarksInfo: SaveQuoteRemarksInfo }>(graphqlQuery);
      const { saveQuoteRemarksInfo } = data;
  
      return saveQuoteRemarksInfo;
      
    }
    catch (err) {
      
    }
    finally {
      loading.value = false;
    }

  }

  const deliveryQuoteValidation = ref();

  const validateDeliveryQuote = async () => {

    if (!totalItems.value){
      return;
    }

  const graphqlQuery = `
    query {
      validateDeliveryQuote (cart_id: "${cart.value.id}") 
        {
          status
        }
      }
  `;
    const { data: { validateDeliveryQuote } } = await query<{ validateDeliveryQuote: DeliveryQuoteValidation }>(graphqlQuery);
    // const { getCheckoutDeliveryGroup } = data;
    deliveryQuoteValidation.value = merge({}, deliveryQuoteValidation.value, validateDeliveryQuote);
  }

  return {
    getProductDelivery,
    cartDeliveryGroup,
    getCartDeliveryGroup,
    loadingCartDeliveryGroup,
    getFormattedDate, 
    getDisplayDate,
    getCheckoutDeliveryGroup,
    loadingCheckoutDeliveryGroup,
    checkoutDeliveryGroup,
    cart_id, 
    saveQuoteDeliveryInfo,
    saveQuoteDeliveryInfoResponse, 
    timeslots, 
    getDeliveryGroupTimeslotsForDate, 
    delivery_quote, 
    getDeliveryQuote, 
    deliveryQuoteValidation, 
    validateDeliveryQuote,
    loading,
    saveQuoteRemarks
  }
}


export default useFtDelivery;