






























































































































































import {
  SfLoader
} from '@storefront-ui/vue';
import {
  ref,
  defineComponent,
  computed,
  onMounted,
  useFetch,
  useRouter,
  useContext,
} from "@nuxtjs/composition-api";
import { useCart } from '~/modules/checkout/composables/useCart';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useUiNotification } from '~/composables';
import useCartSelectedPaymentMethod from '~/modules/fortytwo/product/composables/useCartSelectedPaymentMethod';
import useMakeOrder from "~/modules/checkout/composables/useMakeOrder";
import { removeItem } from "~/helpers/asyncLocalStorage";
import useFtPaymentRedirect from "~/modules/fortytwo/payment/composables/useFtPaymentRedirect"
import useFtDelivery from '~/modules/fortytwo/delivery/composables/useFtDelivery';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";
import GA4Checkout from '~/modules/fortytwo/googleanalytics4/components/GA4Checkout.vue';

export default defineComponent({
  name: "ReviewOrderAndPaymentV1", 
  components: {
    FtButton,
    SfLoader,
    GA4Checkout
  },
  setup() {
    const order = ref(null);
    const customerremarks = ref('');
    const { cart, load, setCart } = useCart();
    const router = useRouter();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $cookies, $recaptcha, $config } = useContext();
    const { getCartSelectedPaymentMethod } = useCartSelectedPaymentMethod();
    const selectedShippingMethod:any = computed(() => cartGetters.getSelectedShippingMethod(cart.value));
    const {products,getAttributes,getBundles} = useCartView();
    
    const items:any = products;
    const address = cart.value.shipping_addresses;
    const price = cart.value.prices;
    const paymentmethod = ref("");
    const paymentcode = ref("");
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));
    const { make, loading, error } = useMakeOrder();
    const triggerCheckoutOption = ref(false);
    const option = ref("");
    const { paymentRedirect } = useFtPaymentRedirect();
    const { saveQuoteRemarks } = useFtDelivery();
    const { send: sendNotification } = useUiNotification();
    const isTermChecked = ref(false);
    
    // alert box prompt the message via cart parameter "error". 
    const onError = async (message) => {
        sendNotification({
          id: Symbol('checkout_error'),
          message: message,
          type: 'danger',
          icon: '',
          persist: false,
          title: 'Checkout Error',
        });
    };

    const validateCheckError = () => {
      sendNotification({
        id: Symbol('validate_checkbox'),
        message: "Please agree to all the terms and conditions before placing the order.",
        type: 'danger',
        icon: '',
        persist: false,
        title: 'Validate Check',
      });
    };

    const getRecaptchaInfo = async (isRecaptchaOn: boolean) : Promise<{ token: () => Promise<string> | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        return { token: async () => await $recaptcha.execute(), cleanup: () => { $recaptcha.destroy(); } };
      }
      return { token: null, cleanup: () => {} };
    };

    useFetch(async () => {
      const { data } = await getCartSelectedPaymentMethod(cart.value.id);
      paymentcode.value = data.cart?.selected_payment_method?.code ?? "";
      if (paymentcode.value == 'chcybersource'){
        paymentmethod.value = "Secured Credit/Debit Card Payment";
      }else if(paymentcode.value == 'dbsipp'){
        paymentmethod.value = "DBS / POSB 0% Interest-Free Instalment Payment Plan";
      }else if(paymentcode.value == 'p2c2p'){
        paymentmethod.value = "UOB 0% Interest-Free Instalment Payment Plan";
      }else if(paymentcode.value == 'grabpay'){
        paymentmethod.value = "GrabPay";
      }else if(paymentcode.value == 'paypos'){
        paymentmethod.value = "Pay at POS";
      }else if(paymentcode.value == 'paypal_express'){
        paymentmethod.value = "Paypal";
      }
    });

    const processOrder = async () => {
      option.value = paymentcode.value;
      if(!isTermChecked.value){
        validateCheckError();
      }else{
        await saveQuoteRemarks(customerremarks.value);
        const { token, cleanup } = await getRecaptchaInfo(isRecaptchaEnabled.value);
        triggerCheckoutOption.value = true;
        
        if(paymentcode.value !== "paypal_express"){
          // put to recaptcha token, 1st one put in custom header for magento, 2nd one used to verify before sending graphql to magento
          order.value = await make({ customHeaders: { 'X-ReCaptcha': await token() } }, await token());
          cleanup();
          if (!error.value.make){
            setCart(null);
            app.$vsf.$magento.config.state.removeCartId();
            // await load();
            await removeItem("checkout");
            // payment redirect 
            await paymentRedirect(paymentcode.value,window.btoa(order.value.order.order_number));
          }
          else{
            // load customer cart again just in case there is oos item
            await load();
            // redirect to cart page
            await router.push(app.localePath("/cart"));
            // show error message 
            await onError(app.i18n.t(`${error.value.make}`));
          }
          
        }else{
          await paymentRedirect(paymentcode.value,app.$vsf.$magento.config.state.getCartId());
        }
      }
    };

    onMounted(() => {
      if (isRecaptchaEnabled.value){
        $recaptcha.init();
      }
    })
  
    return {
      paymenttxt : computed(() => paymentmethod.value != '' ? paymentmethod.value : ''),
      items,
      products,
      address,
      price,
      getShippingMethodPrice,
      selectedShippingMethod,
      getAttributes,
      getBundles,
      paymentcode,
      customerremarks,
      // getDeliveryAgencyName,
      processOrder,
      loading,
      isTermChecked,
      triggerCheckoutOption,
      option
    };
  },
});
