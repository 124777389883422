<template><div id="GA4Checkout"></div></template>
<script>
import { onMounted } from "@nuxtjs/composition-api";
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';

export default {
  name: "GA4Checkout",
  props: {
    step:{
    type: String,
    },
    option:{
    type: String,
    },
    version:{
      type: String,
    },
    triggerCheckoutOption:{
    type: Boolean,
    },
    clickTriggerPage:{
    type: Boolean,
    },
  },
  methods: {
    trackCheckout() {
      if(this?.user?.email){
        const userId = sha256.hex(this.user.email);
        this.$gtag.event("userIdSet", {
          'checkout': { actionField:{ step:this.step, option:this.option }},
          'user_id': userId
        });
      }
      let eventName = "checkout";

      if(this.version == "V1"){
        if(this.step == 0){eventName = "checkout_login_v1";}
        if(this.step == 1){eventName = "begin_checkout_v1";}
        if(this.step == 2){eventName = "checkout_shipping_method_info_v1";}
        if(this.step == 3){eventName = "checkout_billing_info_v1";}
        if(this.step == 4){eventName = "checkout_delivery_info_v1";}
        if(this.step == 5){eventName = "checkout_payment_method_v1";}
        if(this.step == 6){eventName = "checkout_order_review_info_v1";}
        if(this.step == 7){eventName = "checkout_payment_gateway_v1";}
      }else{
        if(this.step == 0){eventName = "checkout_login";}
        if(this.step == 1){eventName = "begin_checkout";}
        if(this.step == 2){eventName = "checkout_shipping_method_info";}
        if(this.step == 3){eventName = "checkout_billing_info";}
        if(this.step == 4){eventName = "checkout_delivery_info";}
        if(this.step == 5){eventName = "checkout_payment_method";}
        if(this.step == 6){eventName = "checkout_payment_gateway";}

      }
      
      this.$gtag.event(eventName, {
        'checkout': { actionField:{ step:this.step, option:this.option }},
      });

      if(this.triggerCheckoutOption){
        this.$emit("triggerCheckoutOptionFlag",false);
      }

    }
  },
  watch: {
    triggerCheckoutOption() {
      if(this.triggerCheckoutOption){
        this.trackCheckout();
      }
    },
  },
  mounted(){
    if(!this.clickTriggerPage){
      this.trackCheckout();
    }
  },
  setup() {
    const { user, load: loadUser } = useUser();
    onMounted(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });
    return{
      user,
    }
  }
};
</script>

  